import React from 'react'

// Components
import Wrapper from '../../Components/Wrapper/Wrapper'
import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'
import LoaderWrapper from '../../Components/Loader/LoaderWrapper'

// Libraries
import { Link } from 'react-router-dom'

// Image
import NotFound from '../../Assets/images/404.png'

// Data
import options from '../../Data/particales'

// Icons
import { AiOutlineArrowLeft } from 'react-icons/ai'
// import logo from "../../Assets/icons/logo.svg";

// Style
import Style from './NotFoundView.module.css'

const NotFoundView = () => {
  const particlesInit = async (main) => {
    console.log(main)
    await loadFull(main)
  }
  return (
    <LoaderWrapper>
      <Wrapper>
        <div className={Style.topOf}>
          <Link to="/">{/* <img src={logo} alt="logo" /> */}</Link>
          <Link className={Style.backBtn} to="/">
            <AiOutlineArrowLeft />
            <p>Go TO MAIN PAGE</p>
          </Link>
        </div>
        <div className={Style.notFound}>
          {/* <h1>ERROR 404</h1>
        <h3>Page not found</h3> */}
          <img src={NotFound} className={Style.notFoundImage} alt="" />
        </div>
      </Wrapper>
      <Particles id="tsparticles" init={particlesInit} options={options} />
    </LoaderWrapper>
  )
}

export default NotFoundView
