import React from "react";

// Style
import Style from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={Style.footerWrapper}>
      <p>Twoaccess Technology LTD</p>
      <p>
        company number: <strong>2142464</strong>{" "}
      </p>
      <p>
        Registered address:{" "}
        <strong>
          Skelton Bay Lot, Fish Bay, Tortola, British Virgin Islands
        </strong>
      </p>
    </div>
  );
};

export default Footer;
