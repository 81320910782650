import React from 'react'

// Style
import Style from './Loader.module.css'

const Loader = () => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.loader}></div>
    </div>
  )
}

export default Loader
