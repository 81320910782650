import React from "react";

// Utils
import classNames from "../../utils/classNames";

// Styles
import Style from "./Wrapper.module.css";

const Wrapper = ({
  baseClassName,
  right,
  left,
  extraPadding = false,
  children,
  ...props
}) => (
  <div
    className={classNames(
      extraPadding ? Style.extraPadding : Style.wrapper,
      baseClassName
    )}
    {...props}
    style={{
      paddingLeft: left ? "0" : "",
      paddingRight: right ? "0" : "",
    }}
  >
    {children}
  </div>
);

export default Wrapper;
