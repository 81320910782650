import React from "react";

// Component
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Wrapper from "../../Components/Wrapper/Wrapper";
import LoaderWrapper from "../../Components/Loader/LoaderWrapper";
import { BiSupport } from "react-icons/bi";

// Data
import options from "../../Data/particales";

// Images
import LOGO from "../../Assets/images/logo.svg";

// Styles
import Style from "./HomeView.module.css";

const HomeView = () => {
  const particlesInit = async (main) => {
    // console.log(main)
    await loadFull(main);
  };
  return (
    <LoaderWrapper>
      <div className={Style.home}>
        <Wrapper>
          <div className={Style.wrapper}>
            <img src={LOGO} alt="" className={Style.logoImg} />
            <div className={Style.mainContent}>
              <h2 className={Style.mainHead}>
                <span>Introducing Homnifi</span>
              </h2>
              <p className={Style.subHead}>
                !We are thrilled to inform you that <span>2access.io</span> has
                evolved into a brand-new platform: Homnifi. During our
                maintenance period,we have been dedicated to creating an
                enhanced experience for you. <br></br> <br></br> We invite you to visit our new home
                at{" "}
                <a
                  href="https://homnifi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={Style.homniLink}
                >
                  https://homnifi.com
                </a>{" "}
                to discover the latest features and improvements. Click the link
                below to explore with Homnifi:
              </p>
              <a
                href="https://homnifi.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={Style.buttonPt}
              >
                Visit Homnifi
              </a>
            </div>
            <div className={Style.footer}>
              <a
                href="https://support.homnifi.com"
                target="_blank"
                rel="noopener noreferrer"
                className={Style.supportBtn}
              >
                <BiSupport />
                Support
              </a>
            </div>
          </div>
        </Wrapper>
      </div>
      <Particles id="tsparticles" init={particlesInit} options={options} />
    </LoaderWrapper>
  );
};

export default HomeView;
